import React from 'react'
import PropTypes from 'prop-types'

const OpenQuote = ({ color, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			aria-hidden="true"
			focusable="false"
			role="img"
            className="closing-quote"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 145.48 96"
		>
            <path fill={color} class="cls-2" d="M29.9,0c29.58.22,46.64,30.6,34.45,55.81C57.85,69.3,46.74,78.48,34.78,86.72,30.53,89.64,26,92.14,21.66,95c-2.89,1.88-4.76,1.17-6.75-1.54s-2-4.43.64-6.8A79.92,79.92,0,0,0,26.74,74.82c5-6.87,3.75-10-4.19-13.23-22.16-8.9-29-29.27-16-48.15C12.92,4.25,22.12.23,29.9,0Z"/>
            <path fill={color} class="cls-2" d="M145.47,38.87c.33,11.92-5,21.48-12.61,30.05-9.31,10.51-20.79,18.25-32.73,25.38-2.63,1.57-5.14,3-7.57-.73-2.23-3.44-.7-5.37,1.76-7.58,4.65-4.19,9-8.69,11.79-14.44,2.14-4.33,1-7.29-3.29-9.17-1.74-.76-3.55-1.37-5.31-2.11-15.36-6.45-20.5-13.7-19.91-28a33.4,33.4,0,0,1,23-30.61c12.54-4.2,27.2-.05,35.47,10.09A41.38,41.38,0,0,1,145.47,38.87Z"/>
		</svg>
	)
}

OpenQuote.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
OpenQuote.defaultProps = {
	color: 'currentColor',
	width: '24',
	height: '24',
}
export default OpenQuote
