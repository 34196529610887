import React from 'react'
import PropTypes from 'prop-types'
const EcoSwimLogo = ({ size, primary, secondary, ...otherProps }) => (
	<svg
		id="eco-swim-logo"
		aria-hidden="true"
		focusable="false"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		aria-label="Eco Swim Logo"
		width={size ? size : ''}
		viewBox="0 0 629 168"
		{...otherProps}
	>
        <g class="cls-1">
            <g id="Layer_2" data-name="Layer 2"><g id="or.....ICE_CREAM_:D" data-name="or.....ICE CREAM? :D">
                <path fill={primary} class="cls-3" d="M36.49,143.79q-8.51,0-14-3a22.32,22.32,0,0,1-8.66-8.42,37.26,37.26,0,0,1-4.4-12.76A96.8,96.8,0,0,1,8.2,103.45,91.72,91.72,0,0,1,9.83,85.14a33.16,33.16,0,0,1,5.56-13.29,24.51,24.51,0,0,1,10.74-8.11Q32.92,61,43.28,61a58,58,0,0,1,14.07,1.63,29.37,29.37,0,0,1,11.2,5.35,24.84,24.84,0,0,1,7.34,9.7,36.18,36.18,0,0,1,2.63,14.65q0,7.14-2.39,10.32t-9.35,3.17H16.55a85.56,85.56,0,0,0,.69,10.79A33.1,33.1,0,0,0,20,126.43a18,18,0,0,0,6,7.19q3.94,2.76,10.44,2.75H76.67v7.42ZM66.78,98.36a2.82,2.82,0,0,0,2.7-1.23,4.91,4.91,0,0,0,.7-2.47V93.59A36.58,36.58,0,0,0,68.4,81.21a17.46,17.46,0,0,0-5.25-7.69,20.38,20.38,0,0,0-8.43-4,49.84,49.84,0,0,0-11.28-1.16,45.38,45.38,0,0,0-11.9,1.39,17.81,17.81,0,0,0-8.42,4.84,21.36,21.36,0,0,0-4.95,9.23,54.71,54.71,0,0,0-1.62,14.53Z"/>
                <path fill={primary} class="cls-3" d="M124.27,143.79q-10.51,0-17.16-2.7a24.56,24.56,0,0,1-10.58-7.88,30.24,30.24,0,0,1-5.41-12.83,93.67,93.67,0,0,1-1.47-17.54A88.69,88.69,0,0,1,91.12,86a31.38,31.38,0,0,1,5.41-12.9,25.74,25.74,0,0,1,10.58-8.27q6.65-2.94,17.16-2.94h27.51V69.3H124.27q-8.19,0-13.29,2.23A18.07,18.07,0,0,0,103,78a26.57,26.57,0,0,0-3.94,10.54A84.18,84.18,0,0,0,98,102.84a88.45,88.45,0,0,0,1.09,14.92A26,26,0,0,0,103,128.22a16.78,16.78,0,0,0,8,6.15q5.1,2,13.29,2h29.36v7.42Z"/>
                <path fill={primary} class="cls-3" d="M199.07,61a63.56,63.56,0,0,1,18.7,2.4,28.86,28.86,0,0,1,12.6,7.49,29.94,29.94,0,0,1,7,13,71.52,71.52,0,0,1,2.17,18.85,72.37,72.37,0,0,1-2.17,18.93,29.86,29.86,0,0,1-7,13.06,29.38,29.38,0,0,1-12.6,7.58,62.09,62.09,0,0,1-18.7,2.47,62.09,62.09,0,0,1-18.7-2.47,29.4,29.4,0,0,1-12.59-7.58,29.88,29.88,0,0,1-7-13.06,72.84,72.84,0,0,1-2.16-18.93,72,72,0,0,1,2.16-18.85,30,30,0,0,1,7-13,28.88,28.88,0,0,1,12.59-7.49A63.56,63.56,0,0,1,199.07,61Zm-32.14,41.73a64.73,64.73,0,0,0,1.77,16.46,24.53,24.53,0,0,0,5.64,10.7,21.22,21.22,0,0,0,10,5.77,55.81,55.81,0,0,0,14.76,1.69,55.81,55.81,0,0,0,14.76-1.69,21.17,21.17,0,0,0,10-5.77,24.43,24.43,0,0,0,5.64-10.7,64.23,64.23,0,0,0,1.78-16.46,63.41,63.41,0,0,0-1.78-16.38,23.86,23.86,0,0,0-5.64-10.54,21.57,21.57,0,0,0-10-5.7,56.23,56.23,0,0,0-14.76-1.69,56.23,56.23,0,0,0-14.76,1.69,21.62,21.62,0,0,0-10,5.7A24,24,0,0,0,168.7,86.3,63.9,63.9,0,0,0,166.93,102.68Z"/>
                <path fill={secondary} class="cls-3" d="M352.78,132.08c.21.52.44,1.06.7,1.62s.59.85,1,.85.88-.33,1.09-1a10.73,10.73,0,0,1,.46-1.32l17.16-66.15c.82-2.88,2-4.71,3.55-5.48a8.09,8.09,0,0,1,8.35,0c1.54.77,2.73,2.6,3.55,5.48l17.16,66.15a12.85,12.85,0,0,1,.46,1.32c.21.67.57,1,1.08,1s.75-.28,1-.85.49-1.1.69-1.62l18.39-71.71h8l-20.09,76a14.18,14.18,0,0,1-2.4,4.71q-1.62,2.08-5.48,2.09a8.13,8.13,0,0,1-3.71-.78,7.73,7.73,0,0,1-2.47-1.85,9.81,9.81,0,0,1-1.47-2.24,7.76,7.76,0,0,1-.7-2.09l-16.69-64a18.85,18.85,0,0,0-.7-2c-.25-.62-.54-.93-.84-.93s-.6.31-.86.93a18.87,18.87,0,0,0-.69,2l-16.69,64c-.21.62-.47,1.32-.78,2.09a8,8,0,0,1-3.78,4.09,8.05,8.05,0,0,1-3.79.78c-2.58,0-4.4-.7-5.49-2.09a14,14,0,0,1-2.39-4.71l-20.09-76h8Z"/>
                <path fill={secondary} class="cls-3" d="M443.82,46.15V34.09h8.65V46.15Zm.31,14.22h8v81.91h-8Z"/>
                <path fill={secondary} class="cls-3" d="M469.16,73.35q0-5.88,2.32-9.43t8.81-3.55h75.88q12.84,0,18.24,6.56t5.41,18.63v56.72h-8V85.68a33.19,33.19,0,0,0-.69-6.94A15,15,0,0,0,568.62,73a12,12,0,0,0-4.8-3.86,18.4,18.4,0,0,0-7.65-1.38H528.51v74.49h-8V67.79H482.14a6.21,6.21,0,0,0-3.63.92c-.88.62-1.31,2.26-1.31,4.94v68.63h-8Z"/>
                <path fill={secondary} class="cls-3" d="M317.46,105.4a30.25,30.25,0,0,1-4.6,14.29,31.56,31.56,0,0,1-4.73,6c-.18-.46.09-.59.21-.78A28.46,28.46,0,0,0,313,109a30,30,0,0,0-2.54-11,62.65,62.65,0,0,0-8.79-14.31c-4.64-5.94-9.68-11.34-14.62-16.88a131.55,131.55,0,0,1-11.51-14.3,44.43,44.43,0,0,1-5.69-11.13,23.9,23.9,0,0,1,.52-16.89c3-7.24,7.78-11.66,14-14A27.37,27.37,0,0,1,294.49,9l.56,0c-1,1.08-1.93,1.94-2.75,3-5.13,6.56-7.43,14.53-6.67,24a40,40,0,0,0,4.93,15.5,141.43,141.43,0,0,0,9.68,15.08c4.73,6.79,9.41,13.61,13.23,21.23"/>
                <path fill={primary} class="cls-3" d="M272,145.81a25.89,25.89,0,0,0,6-7.87c4.18-8.48,4.89-17.64,1.68-27.48A57.5,57.5,0,0,0,273,97.27c-3-4.71-6.29-9.19-9.42-13.8a139.79,139.79,0,0,1-10.13-16.85c-4.57-9.3-5.22-18.52-1.23-27.63a34.39,34.39,0,0,1,6.61-9.77c.1-.1.2-.26.45-.16-.9,1.5-1.78,3-2.53,4.56a26.68,26.68,0,0,0-1.49,19.53,44.54,44.54,0,0,0,5.25,11.12,85.12,85.12,0,0,0,6.09,8.41c4.26,5.31,8.83,10.21,13.31,15.23A147.05,147.05,0,0,1,290,100.15a51.7,51.7,0,0,1,6.74,11.93,24.08,24.08,0,0,1,.25,17.55c-2.81,7.36-7.48,12.06-13.76,14.51a26.27,26.27,0,0,1-10.63,1.77C272.46,145.91,272.35,145.87,272,145.81Z"/>
                <path fill={secondary} class="cls-3" d="M295,9c-1,1.08-1.93,1.94-2.75,3-5.13,6.56-7.43,14.53-6.67,24a40,40,0,0,0,4.93,15.5,141.43,141.43,0,0,0,9.68,15.08c4.73,6.79,9.41,13.61,13.23,21.23a34,34,0,0,1,4,17.54,30.15,30.15,0,0,1-4.61,14.3,30.74,30.74,0,0,1-4.72,6c-.18-.46.09-.59.21-.78A28.38,28.38,0,0,0,313,109a30,30,0,0,0-2.54-11,62.65,62.65,0,0,0-8.79-14.31C297,77.75,292,72.35,287.05,66.81a131.55,131.55,0,0,1-11.51-14.3,44.43,44.43,0,0,1-5.69-11.13,23.9,23.9,0,0,1,.52-16.89c3-7.24,7.78-11.66,14-14A27.58,27.58,0,0,1,294.46,9L295,9"/>
                <path fill={secondary} class="cls-3" d="M589.88,41.69V30.56c0-1,.21-1.82,2.29-1.82h5.27c2.72,0,3.73,1.18,3.73,4.16,0,3.62-1.81,3.89-2.82,3.89H597l4.85,4.9H599l-4.32-4.37A1.48,1.48,0,0,1,594,36c0-1,.7-1,1.12-1H597c.74,0,1.92,0,1.92-1.06V32.1a1.53,1.53,0,0,0-1.71-1.49h-4.31c-.64,0-.91.11-.91,1V41.69Zm14.86-6.12c0-6.72-1.86-9.8-8.89-9.8-6.4,0-9.54,1.75-9.54,9.42,0,6.61,1.7,9.91,8.63,9.91,6.77,0,9.8-1.81,9.8-9.53m-20.56-.27c0-8.15,3-11.35,11.45-11.35,7.89,0,11.25,2.5,11.25,11.35,0,8.52-3.25,11.61-11.89,11.61-7.46,0-10.81-3.19-10.81-11.61"/>
                </g>
            </g>
        </g>
	</svg>
)
EcoSwimLogo.propTypes = {
	size: PropTypes.string,
	primary: PropTypes.string,
	secondary: PropTypes.string,
}
EcoSwimLogo.defaultProps = {
	size: '',
	primary: '#57c8de',
	secondary: '#598aa9',
}
export default EcoSwimLogo


// <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 629 168" style="&#10;    background: darkblue;&#10;"><defs><style>.cls-1{isolation:isolate;}.cls-2{opacity:0.2;mix-blend-mode:multiply;}.cls-3{fill:#fff;}</style></defs></svg>