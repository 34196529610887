import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import filterLocale from '../utils/filter-locale'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
	TechSection,
	TechContainer,
	ItemContainer,
	ImageTechContainer,
	TextTechContainer,
	TechParagraph,
	TechList,
	Techtitle,
	TechSubText,
	TechLearnMore,
} from './styles'

const Technology = ({ locale }) => {
	const { tech } = useStaticQuery(graphql`
		{
			tech: allContentfulBanner(
				filter: {
					contentful_id: {
						in: ["6OF5NpTGU6Z5mkkGBAkejY","6uSGMHQcXhqzDW1UwdI0M8", "15NJyIXAwE32VumVta0caH"]
					}
				}
				sort: { fields: id, order: ASC}
			) {
				edges {
					node {
						node_locale
						hook
						subtext
						mainActionText
						mainActionUrl
						backgroundImage {
							description
							fluid(maxHeight: 500, quality: 80) {
								src
								srcWebp
							}
						}
						description {
							json
						}
					}
				}
			}
		}
	`)

	const techs = filterLocale(tech.edges, locale)

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<TechParagraph>{children}</TechParagraph>
			),
			[BLOCKS.UL_LIST]: (node, children) => <TechList>{children}</TechList>,
			[BLOCKS.LIST_ITEM]: (node, children) => (
				<li>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 24 24"
						fill="none"
						stroke="#fff"
						stroke-width="3"
						stroke-linecap="round"
						stroke-linejoin="round"
					>
						<polyline points="20 6 9 17 4 12"></polyline>
					</svg>
					{children}
				</li>
			),
		},
	}
	return (
		<TechSection>
			<TechContainer>
				{techs.map((tech, i) => {
					return (
						<ItemContainer key={i}>
							<ImageTechContainer
								src={tech.node?.backgroundImage?.fluid.src}
								srcwebp={tech.node?.backgroundImage?.fluid.srcWebp}
								className={'image'}
							/>
							<TextTechContainer className={'text'}>
								<Techtitle>{tech.node.hook}</Techtitle>
								<TechSubText>{tech.node.subtext}</TechSubText>
								{documentToReactComponents(tech.node.description.json, options)}

								{tech.node.mainActionText && tech.node.mainActionUrl ? (
									<TechLearnMore to={`${tech.node?.mainActionUrl}/ `}>
										{tech.node?.mainActionText}
									</TechLearnMore>
								) : (
									''
								)}
							</TextTechContainer>
						</ItemContainer>
					)
				})}
			</TechContainer>
		</TechSection>
	)
}

export default Technology
