import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filterLocale from '../utils/filter-locale'
import { useSpring } from 'react-spring'
import OpenQuote from '../svg/open-quote'
import ClosingQuote from '../svg/closing-quote'
import {
    TestimonialSection,
    TestimonialContainer,
    TestimonialP,
    TestimonialContent,
    FaqPrev,
    FaqNext,
    AnimatedFaqTitle,
    Author,
    AuthorDescription,
    MoreTestimonial,
} from './styles'

const Testimonials = ({ locale }) => {

    const { banner, testi } = useStaticQuery(graphql`
        {
            banner:allContentfulBanner(filter: {contentful_id: {eq: "71EcWrTojipyRXRmHErJx0"}}) {
                edges {
                    node {
                        node_locale
                        hook
                        subtext
                        mainActionText
                        mainActionUrl
                    }
                }
            }
            testi:allContentfulTestimonials {
                edges {
                    node {
                        node_locale
                        author
                        authorDescription
                        body {
                            json
                        }
                    }
                }
            }
        }
    `)
    const [{ node }] = filterLocale(banner.edges, locale)
    const testimonial = filterLocale(testi.edges, locale).splice(0, 5)
	const [faqs, setFaqs] = useState(testimonial)
	const [activeFaq, setActiveFaq] = useState(null)
	const [currentIndex, setCurrentIndex] = useState(0)

	useEffect(() => {
		const newState = faqs.map((faq, i) => {
			const defaultIndex = 0
			return { ...faq, isActive: defaultIndex === i ? true : false }
		})
		setFaqs(newState)
		setActiveFaq(newState[0])
	}, [])

	const prevFaqHolder = (current) => {
		current = current - 1
		if (current != 0 || current === 0) {
			setCurrentIndex(current)
			setActiveFaq(faqs[current])
		}

		if (current < 0 ) {
			current = faqs.length - 1
			setCurrentIndex(current)
			setActiveFaq(faqs[current])
		}
	}

	const nextFaqHolder = (current) => {
		current = current + 1
		if ( current < faqs.length ) {
			setCurrentIndex(current)
			setActiveFaq(faqs[current])
		}
		if ( current === faqs.length ) {
			current = 0
			setCurrentIndex(current)
			setActiveFaq(faqs[current])
		}

	}

    const props = useSpring({
		from: { height: 0, opacity: 0, transform: 'translate3d(0,-20px,0)' },
		to: {
			height: 'auto',
			opacity: 1,
			transform: `translate3d(0,0,0)`,
		},
		reset: true,
	})

    const option = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <TestimonialP>{children}</TestimonialP>,
        }
    }

    return (
        <TestimonialSection>
            <TestimonialContainer>
                <h2>{node.hook}</h2>
                <p>{node.subtext}</p>
                <OpenQuote width="80" height="80"/>
                <ClosingQuote width="80" height="80"/>
                <TestimonialContent>
                    <AnimatedFaqTitle style={props}>
                        {activeFaq ? documentToReactComponents(activeFaq.node.body.json, option) : ''}
                        <Author>{activeFaq ? activeFaq.node.author : ''}</Author>
                        <AuthorDescription>{activeFaq ? activeFaq.node.authorDescription : ''}</AuthorDescription>
                    </AnimatedFaqTitle>
                    <FaqPrev onClick={() => { prevFaqHolder( currentIndex ) }} width="60" height="60"/>
                    <FaqNext onClick={() => { nextFaqHolder( currentIndex ) }} width="60" height="60"/>
                </TestimonialContent>
                <MoreTestimonial to={`${node.mainActionUrl}/ `}>{node.mainActionText}</MoreTestimonial>
            </TestimonialContainer>
        </TestimonialSection>
    )
}

export default Testimonials
