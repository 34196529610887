import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filterLocale from '../utils/filter-locale'
import useLocale from '../../hooks/useLocale'
const windowGlobal = typeof window !== 'undefined' && window

import {
	RobotsSection,
	RobotsContainer,
	RobotItem,
	ResidentialTitle,
	ResidentialSub,
	ResidentialSubtext,
	ResidentialParagprah,
	RobotTextContainer,
	RobotImageContainer,
	ResidentialInnerContainer,
	CommercialTitle,
	CommercialSub,
	CommercialLearnMore,
} from './styles'

const Robots = ({ locale }) => {
	const { global, leaders } = useStaticQuery(graphql`
		{
			global: allContentfulBanner(
				filter: { contentful_id: { eq: "33SQVItEmMys35o3Kkp4PH" } }
			) {
				edges {
					node {
						node_locale
						hook
						subtext
						mainActionUrl
						mainActionText
						backgroundImage {
							description
							fluid(maxWidth: 700, maxHeight: 700, quality: 40) {
								src
								srcWebp
							}
						}
						description {
							json
						}
					}
				}
			}
			leaders: allContentfulBanner(
				filter: { contentful_id: { eq: "1RUmDyj3wVR75FS4eEymIE" } }
			) {
				edges {
					node {
						node_locale
						hook
						subtext
						mainActionUrl
						mainActionText
						backgroundImage {
							description
							fluid(maxWidth: 700, maxHeight: 700, quality: 40) {
								src
								srcWebp
							}
						}
						description {
							json
						}
					}
				}
			}
		}
	`)

	const [{ node }] = filterLocale(global.edges, locale)
	const [{ node: leader }] = filterLocale(leaders.edges, locale)
	const [first, ...rest] = node.description.json.content
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<ResidentialSubtext>{children}</ResidentialSubtext>
			),
		},
	}
	const option = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<ResidentialParagprah>{children}</ResidentialParagprah>
			),
		},
	}
	const nodecopy = {
		...node,
	}
	const altercopy = {
		...nodecopy,
		description: {
			json: {
				content: [...rest],
			},
		},
	}

	const [pathname, setPathName] = useState('')
	useEffect(() => {
		const path = windowGlobal.location.pathname
		setPathName(path)
	}, [])
	const { globalLocale } = useLocale(pathname)

	const checkUrl =
		globalLocale !== 'en-AU'
			? leader.mainActionUrl.includes(globalLocale)
			: null

	return (
		<RobotsSection>
			<RobotsContainer>
				<RobotItem>
					<ResidentialTitle>{node.hook}</ResidentialTitle>
					<ResidentialSub>{node.subtext}</ResidentialSub>
					{documentToReactComponents(first, options)}
					<ResidentialInnerContainer>
						<RobotTextContainer>
							{documentToReactComponents(altercopy.description.json, option)}
							<CommercialLearnMore to={`${node.mainActionUrl}/ `}>
								{node.mainActionText}
							</CommercialLearnMore>
						</RobotTextContainer>
						<RobotImageContainer>
							<picture>
								<source
									srcSet={node?.backgroundImage?.fluid.srcWebp}
									type="image/webp"
								></source>
								<img
									src={node?.backgroundImage?.fluid.src}
									alt={node?.backgroundImage.description}
									loading="lazy"
								/>
							</picture>
						</RobotImageContainer>
					</ResidentialInnerContainer>
				</RobotItem>
				{checkUrl === null || checkUrl !== false ? (
					<RobotItem>
						<CommercialTitle>{leader.hook}</CommercialTitle>
						<CommercialSub>{leader.subtext}</CommercialSub>
						<ResidentialInnerContainer>
							<RobotTextContainer>
								{documentToReactComponents(leader.description.json, option)}
								<CommercialLearnMore to={`${leader.mainActionUrl}/ `}>
									{leader.mainActionText}
								</CommercialLearnMore>
							</RobotTextContainer>
							<RobotImageContainer>
								<picture>
									<source
										srcSet={leader?.backgroundImage?.fluid.srcWebp}
										type="image/webp"
									></source>
									<img
										src={leader?.backgroundImage?.fluid.src}
										alt={leader?.backgroundImage.description}
										loading="lazy"
									/>
								</picture>
							</RobotImageContainer>
						</ResidentialInnerContainer>
					</RobotItem>
				) : (
					''
				)}
			</RobotsContainer>
		</RobotsSection>
	)
}

export default Robots
