import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import tw, { css, styled } from 'twin.macro'
import filterLocale from '../utils/filter-locale'
import * as Yup from 'yup'
// for forms
import {
	StyledForm,
	HomeButtonRow,
	HomeButton,
	FieldRow,
	FieldWrap,
	Required,
	StyledError,
} from '../form/styles'
import { Formik, Field } from 'formik'
import assign from 'lodash.assign'
import { flat, validationSwitch, validationSchema } from '../form/utils'
import FormFieldGroup from '../form/FormFieldGroup'
import FormFieldSingle from '../form/FormFieldSingle'
import { SEND_FORM } from '../../app/schema/form'
import { useMutation } from '@apollo/react-hooks'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import localeCheck from '../utils/locale-check'
import {
	Section,
	Container,
	Item,
	FormItem,
	ItemBackgroundGradient,
	ItemDescription,
	MultipleItemContainer,
	MultipleItem,
	MultipleItemLink,
	MultipleItemSplit,
	FirstItemText,
	ItemText,
	LastItemText,
	Bold,
	Logo,
	FormTitle,
	FormDescription,
	PostLink,
	BookButton,
	BookLink,
	BannerContainer,
	Reminder,
	CheckboxContainer,
} from './styles'
import { Lottie } from '@crello/react-lottie'
import { CustomCheckboxInput } from '@reach/checkbox'
import * as animationCheckbox from '../../animations/checkbox.json'

const News = ({ locale }) => {
	const { posts, subscribe, book } = useStaticQuery(graphql`
		query getFeaturedNews {
			posts: allContentfulPost(
				filter: { featured: { in: true } }
				sort: { fields: dateAndTime, order: DESC }
			) {
				edges {
					node {
						seoTitle
						seoKeywords
						seoDescription
						type
						slug
						image {
							fluid(
								quality: 70
								maxWidth: 500
								maxHeight: 500
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
							description
						}
						node_locale
					}
				}
			}
			book: allContentfulBanner(
				filter: { contentful_id: { eq: "3vQmnL8AAtAPdvuYw1Lgfq" } }
			) {
				edges {
					node {
						node_locale
						mainActionText
						mainActionUrl
						description {
							json
						}
						backgroundImage {
							fluid(
								quality: 70
								maxWidth: 400
								maxHeight: 450
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			subscribe: allContentfulForm(
				filter: { contentful_id: { eq: "6taIb1VxqZEulSDN3ZmF4Z" } }
			) {
				edges {
					node {
						title
						description
						endpoint
						node_locale
						image {
							fluid(
								quality: 70
								maxWidth: 300
								maxHeight: 600
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
						items {
							... on ContentfulFormField {
								helpText
								inputAutocomplete
								inputId
								inputName
								inputPlaceholder
								inputRequired
								inputType
								textareaCols
								textareaRows
								icon
								validationText
								width
								options
								node_locale
								label
							}
							... on ContentfulFormGroup {
								title
								items {
									width
									validationText
									textareaRows
									textareaCols
									options
									node_locale
									label
									inputType
									inputRequired
									inputPlaceholder
									inputName
									inputId
									inputAutocomplete
									icon
									helpText
								}
							}
						}
					}
				}
			}
		}
	`)
	/** Filter by locale */
	const post = filterLocale(posts.edges, locale)
	const bookDemo = filterLocale(book.edges, locale)
	const [{ node: subscription }] = filterLocale(subscribe.edges, locale)
	// console.log(subscription.endpoint)
	const valuesMapped = flat(subscription.items).map(item => ({
		[item.inputName]: item.options ? [] : '',
	}))
	valuesMapped.push({ readandagree: false })
	const validationMapped = flat(subscription.items).map(item => ({
		[item.inputName]: validationSwitch(item.inputName, item.validationText),
	}))
	validationMapped.push({ readandagree: validationSwitch('readandagree') })
	/** New Object from mapped Array */
	const valuesObject = assign({}, ...valuesMapped)
	const validationObject = assign({}, ...validationMapped, {
		type: Yup.array()
			.of(Yup.string())
			.min(1, 'At least 1 option')
			.max(5, 'At most 2 options'),
	})
	const [sendForm, { loading, error: formError, data: formData }] = useMutation(
		SEND_FORM
	)

	const option = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<ItemText background="#589bb2" padding="0.5rem 0.75rem">
					{children}
				</ItemText>
			),
		},
	}
	const defaultLocale = 'en-AU'
	const checkedLocale = localeCheck(locale, defaultLocale)
	return (
		<Section>
			<Container>
				<PostLink to={`${checkedLocale}/blog/`}>
					<Item>
						<FirstItemText color="#fff">
						<FormTitle css={tw`mt-4`}>Blogs</FormTitle>
							{/* <ItemDescription>{post[0].node.seoDescription}</ItemDescription> */}
						</FirstItemText>
					</Item>
				</PostLink>
				<MultipleItemContainer>
					{/* <BannerContainer>
						<MultipleItemSplit
							bg={bookDemo[0].node.backgroundImage}
						></MultipleItemSplit>
						{documentToReactComponents(
							bookDemo[0].node.description.json,
							option
						)}
						<BookButton
							background="#589bb2"
							padding="0.25rem 0.75rem 1rem 0.75rem"
						>
							<BookLink
								to={`${checkedLocale}/${bookDemo[0].node.mainActionUrl}`}
							>
								{bookDemo[0].node.mainActionText}
							</BookLink>
						</BookButton>
					</BannerContainer> */}
					<MultipleItemLink to={`${checkedLocale}/book-a-demo/`}>
						<MultipleItem  marginBottom="0">
						</MultipleItem>
					</MultipleItemLink>
					{documentToReactComponents(
							bookDemo[0].node.description.json,
							option
						)}
					<BookButton
							background="#589bb2"
							padding="0.25rem 0.75rem 1rem 0.75rem"
						>
							<BookLink
								to={`${checkedLocale}/${bookDemo[0].node.mainActionUrl}/`}
							>
								{bookDemo[0].node.mainActionText}
							</BookLink>
						</BookButton>
				</MultipleItemContainer>
				<FormItem bg={subscription.image} padding="1.5rem">
					<ItemBackgroundGradient />
					<FormTitle css={tw`relative`}>{subscription.title}</FormTitle>
					<FormDescription>{subscription.description}</FormDescription>
					<Formik
						initialValues={valuesObject}
						validationSchema={validationSchema(validationObject)}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							setSubmitting(true)
							const payload = {
								formName: 'subscribe',
								type: 'subscribe',
								locale: locale,
								...values,
							}
							await sendForm({
								variables: payload,
							})
							if (formError) {
								resetForm()
								setSubmitting(false)
							}
							setSubmitting(false)
							return alert(
								`Thank you for submitting your details, Any questions please call us on 1300 693 657`
							)
						}}
					>
						{({ isSubmitting, errors, touched, values }) => (
							<StyledForm css={tw`relative`}>
								{subscription.items.map((item, index) => {
									if (item.items) {
										const { title, items } = item
										return (
											<FormFieldGroup
												key={title + index}
												color="#fff"
												{...{ items, errors, touched, values }}
											/>
										)
									} else {
										return (
											<FormFieldSingle
												key={item.inputName + index}
												overrideWidth="full"
												color="#fff"
												{...{ item, errors, touched, values }}
											/>
										)
									}
								})}
								<FieldRow>
									<FieldWrap>
										<Field name="readandagree" type="checkbox">
											{({ field, form: { setFieldValue, setTouched } }) => (
												<div tw="flex w-full mb-4 items-center">
													<CheckboxContainer
														tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
														onClick={() =>
															setFieldValue(
																'readandagree',
																field.checked ? false : true
															)
														}
														onKeyDown={() =>
															setFieldValue(
																'readandagree',
																field.checked ? false : true
															)
														}
													>
														<CustomCheckboxInput
															{...field}
															checked={field.checked}
															name="readandagree"
															type="checkbox"
														/>
														<Lottie
															playingState={'playing'}
															speed={1}
															direction={field.checked ? 1 : -1}
															width={24}
															height={24}
															tw="bg-white rounded"
															className="lottie-container checkbox"
															config={{
																animationData: animationCheckbox.default,
																loop: false,
																autoplay: true,
																renderer: 'svg',
															}}
															aria-hidden
														/>
													</CheckboxContainer>
													<p tw="text-sm font-display text-black">
														I have read and I agree to{' '}
														<a
															tw="font-bold underline hover:no-underline"
															href={`${checkedLocale}/terms`}
															target="_blank"
															rel="noreferrer"
														>
															Maytronics Terms &#38; Conditions
														</a>{' '}
														and{' '}
														<a
															tw="font-bold underline hover:no-underline"
															href={`${checkedLocale}/privacy`}
															target="_blank"
															rel="noreferrer"
														>
															Privacy Policy
														</a>{' '}
														<Required>*</Required>
													</p>
												</div>
											)}
										</Field>
										{touched['readandagree'] && errors['readandagree'] ? (
											<StyledError>{`▲ ` + errors['readandagree']}</StyledError>
										) : (
											<></>
										)}
									</FieldWrap>
								</FieldRow>
								<FieldRow>
									<FieldWrap>
										<Field name="updates" type="checkbox">
											{({ field, form: { setFieldValue, setTouched } }) => (
												<div tw="flex w-full mb-4 items-center">
													<CheckboxContainer
														tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
														onClick={() =>
															setFieldValue(
																'updates',
																field.checked ? false : true
															)
														}
														onKeyDown={() =>
															setFieldValue(
																'updates',
																field.checked ? false : true
															)
														}
													>
														<CustomCheckboxInput
															{...field}
															checked={field.checked}
															name="updates"
															type="checkbox"
														/>
														<Lottie
															playingState={'playing'}
															speed={1}
															direction={field.checked ? 1 : -1}
															width={24}
															height={24}
															tw="bg-white rounded"
															className="lottie-container checkbox"
															config={{
																animationData: animationCheckbox.default,
																loop: false,
																autoplay: true,
																renderer: 'svg',
															}}
															aria-hidden
														/>
													</CheckboxContainer>
													<p tw="text-sm font-display text-black">
														I wish to receive updates and promotions from
														Maytronics.
													</p>
												</div>
											)}
										</Field>
									</FieldWrap>
								</FieldRow>
								<HomeButtonRow>
									<HomeButton type="submit" disabled={isSubmitting}>
										{isSubmitting ? 'Subscribing...' : 'Subscribe'}
									</HomeButton>
								</HomeButtonRow>
							</StyledForm>
						)}
					</Formik>
				</FormItem>
			</Container>
		</Section>
	)
}

News.propTypes = {
	locale: PropTypes.string,
}
News.defaultProps = {
	locale: 'en-AU',
}
export default News
