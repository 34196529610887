import React from 'react'
import PropTypes from 'prop-types'

const OpenQuote = ({ color, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			aria-hidden="true"
			focusable="false"
			role="img"
            className="open-quote"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 145.48 96"
		>
            <path fill={color} class="cls-2" d="M115.58,96C86,95.75,68.94,65.36,81.13,40.15c6.5-13.44,17.61-22.62,29.57-30.87,4.25-2.92,8.79-5.41,13.12-8.23,2.89-1.88,4.76-1.16,6.75,1.55s2,4.42-.64,6.8a79.75,79.75,0,0,0-11.18,11.78c-5,6.88-3.76,10.05,4.19,13.24,22.15,8.9,29,29.27,16,48.15C132.56,91.75,123.36,95.78,115.58,96Z"/>
            <path fill={color} class="cls-2" d="M0,57.13c-.34-11.91,5-21.47,12.6-30.05C21.93,16.57,33.41,8.84,45.35,1.7c2.63-1.57,5.14-3,7.57.74,2.23,3.44.7,5.37-1.76,7.58-4.65,4.19-9,8.69-11.79,14.43-2.14,4.34-1,7.29,3.29,9.18,1.75.76,3.55,1.37,5.31,2.11,15.36,6.45,20.5,13.7,19.91,28a33.37,33.37,0,0,1-23,30.6c-12.54,4.2-27.2.05-35.47-10.08A41.33,41.33,0,0,1,0,57.13Z"/>
		</svg>
	)
}

OpenQuote.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
OpenQuote.defaultProps = {
	color: 'currentColor',
	width: '24',
	height: '24',
}
export default OpenQuote
