import React from 'react'
import PropTypes from 'prop-types'
const EcoClearLogo = ({ size, primary, secondary, ...otherProps }) => (
	<svg
		id="ecoclear-swim-logo"
		aria-hidden="true"
		focusable="false"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		aria-label="Ecoclear Swim Logo"
		width={size ? size : ''}
		viewBox="0 0 662.24 122.86"
		{...otherProps}
	>
		<g id="ecoclear" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					fill={secondary}
					d="M638.24,30c0-8.62,3.21-12,12.11-12,8.34,0,11.89,2.65,11.89,12,0,9-3.44,12.29-12.56,12.29-7.89,0-11.44-3.38-11.44-12.29m21.75.29c0-7.1-2-10.37-9.41-10.37-6.76,0-10.09,1.86-10.09,10,0,7,1.81,10.48,9.13,10.48,7.15,0,10.37-1.92,10.37-10.08m-15.72,6.48V25c0-1,.22-1.92,2.42-1.92h5.58c2.87,0,3.94,1.24,3.94,4.4,0,3.83-1.91,4.11-3,4.11h-1.41l5.12,5.19h-3l-4.56-4.63a1.54,1.54,0,0,1-.68-1.4c0-1,.73-1,1.19-1h2c.79,0,2,0,2-1.12v-2a1.6,1.6,0,0,0-1.8-1.57h-4.56c-.68,0-1,.11-1,1V36.77Z"
				/>
				<path
					fill={primary}
					d="M31.84,121.81C6.44,121.81,0,102.67,0,76.39,0,45.24,8.35,28.54,39.5,28.54c22.8,0,39.68,9.05,39.68,35.15,0,10.61-2.79,15.14-13.23,15.14H9.4c0,16.18,2.78,34.8,22.44,34.8H77.09v8.18ZM66,70.65c3,0,3.83-2.44,3.83-4.18V65.26c0-22.63-11.14-28.54-30.11-28.54C19.14,36.72,9.4,44.2,9.4,70.65Z"
				/>
				<path
					fill={primary}
					d="M132.42,121.81c-31.32,0-39-16.53-39-46.11,0-27.85,7.66-46.12,39-46.12h31v8.18h-31c-24.36,0-29.58,13.57-29.58,37.94,0,26.1,5.22,37.93,29.58,37.93h33.06v8.18Z"
				/>
				<path
					fill={primary}
					d="M264,75.52c0,33.07-12.53,47.34-45.59,47.34s-45.59-14.27-45.59-47.34,12.52-47,45.59-47S264,42.46,264,75.52Zm-45.59,39.16c26.1,0,36.19-9.57,36.19-39.16s-10.09-38.8-36.19-38.8-36.2,9.22-36.2,38.8S192.27,114.68,218.38,114.68Z"
				/>
				<path
					fill={secondary}
					d="M317.21,121.81c-31.32,0-39-16.53-39-46.11,0-27.85,7.66-46.12,39-46.12h31v8.18h-31c-24.36,0-29.58,13.57-29.58,37.94,0,26.1,5.22,37.93,29.58,37.93h33.06v8.18Z"
				/>
				<path fill={secondary} d="M364.19,0h9.05V121.81h-9.05Z" />
				<path
					fill={secondary}
					d="M422.48,121.81c-25.41,0-31.84-19.14-31.84-45.42,0-31.15,8.35-47.85,39.5-47.85,22.79,0,39.67,9.05,39.67,35.15,0,10.61-2.78,15.14-13.22,15.14H400c0,16.18,2.79,34.8,22.45,34.8h45.24v8.18Zm34.11-51.16c3,0,3.82-2.44,3.82-4.18V65.26c0-22.63-11.13-28.54-30.1-28.54C409.78,36.72,400,44.2,400,70.65Z"
				/>
				<path
					fill={secondary}
					d="M535.76,29.58c15.31,0,22.1,8.53,22.1,23.15v56.9c0,8.18-2.27,12.18-13.4,12.18H509c-18.27,0-24.89-8.7-24.89-27.84S490.86,66.65,509,66.65h39.85V51.16c0-7-3.31-13.4-13-13.4H491V29.58ZM506.52,74.83c-11.13,0-13,8-13,19.14s1.92,19.66,13,19.66h37.94a4,4,0,0,0,4.35-4.17V74.83Z"
				/>
				<path
					fill={secondary}
					d="M578.38,61.25c0-20.88,9.92-31.67,30.8-31.67h6.27v8.18h-6.27c-15.66,0-21.75,8.18-21.75,25.58v58.47h-9Z"
				/>
			</g>
		</g>
	</svg>
)
EcoClearLogo.propTypes = {
	size: PropTypes.string,
	primary: PropTypes.string,
	secondary: PropTypes.string,
}
EcoClearLogo.defaultProps = {
	size: '',
	primary: '#57c8de',
	secondary: '#598aa9',
}
export default EcoClearLogo
