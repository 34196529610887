import { gql } from 'apollo-boost'
export const GET_BANNER = gql`
	query homeHeroBannersQuery {
		firstBanner: allContentfulBanner(
			filter: { hook: { eq: "The Best Thing You’ll Ever Buy For Your Pool" } }
		) {
			edges {
				node {
					mainActionText
					mainActionUrl
					secondActionText
					secondActionUrl
					node_locale
					hook
					description {
						json
					}
					backgroundImage {
						description
						fluid(
							maxWidth: 1600
							maxHeight: 700
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		firstmd: allContentfulBanner(
			filter: { hook: { eq: "The Best Thing You’ll Ever Buy For Your Pool" } }
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 1400
							maxHeight: 700
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		firstsm: allContentfulBanner(
			filter: { hook: { eq: "The Best Thing You’ll Ever Buy For Your Pool" } }
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 1023
							maxHeight: 600
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		firstxs: allContentfulBanner(
			filter: { hook: { eq: "The Best Thing You’ll Ever Buy For Your Pool" } }
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 767
							maxHeight: 500
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		firstxxs: allContentfulBanner(
			filter: { hook: { eq: "The Best Thing You’ll Ever Buy For Your Pool" } }
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 524
							maxHeight: 400
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		secondBanner: allContentfulBanner(
			filter: {
				hook: { eq: "Discover Your Exceptional Experience with Maytronics" }
			}
		) {
			edges {
				node {
					mainActionText
					mainActionUrl
					secondActionText
					secondActionUrl
					node_locale
					hook
					description {
						json
					}
					backgroundImage {
						fluid(
							maxWidth: 1920
							maxHeight: 800
							quality: 60
							cropFocus: TOP
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		secondmd: allContentfulBanner(
			filter: {
				hook: { eq: "Discover Your Exceptional Experience with Maytronics" }
			}
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 1349
							maxHeight: 700
							quality: 60
							cropFocus: TOP
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		secondsm: allContentfulBanner(
			filter: {
				hook: { eq: "Discover Your Exceptional Experience with Maytronics" }
			}
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 1023
							maxHeight: 600
							quality: 60
							cropFocus: TOP
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		secondxs: allContentfulBanner(
			filter: {
				hook: { eq: "Discover Your Exceptional Experience with Maytronics" }
			}
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 767
							maxHeight: 500
							quality: 60
							cropFocus: TOP
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
		secondxxs: allContentfulBanner(
			filter: {
				hook: { eq: "Discover Your Exceptional Experience with Maytronics" }
			}
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 524
							maxHeight: 400
							quality: 60
							cropFocus: TOP
							resizingBehavior: FILL
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
	}
`
